@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

@keyframes fall {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}

.fall-animation {
  transition: transform 0.5s;
}

.fall-animation.falling {
  animation: fall 0.5s forwards;
}

body {
  overflow: hidden;
}

@keyframes flip {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
}

.animate-flip {
  animation: flip 0.5s ease-in-out;
  animation-iteration-count: 1; /* Reset the iteration count */
}